<template>
  <div class="detail-select-item" :class="{active}">
    <div class="number-container" :class="{active}">
      编号：{{info.materialCode}}
    </div>
    <div class="detail-info-container">
      <div class="image-container">
        <img :src="info.largerUrl" class="image" />
      </div>
      <div class="detail-info-wrap" :class="{active}">
        <div class="name">{{info.materialName}}</div>
        <div class="unit-weight-container" :class="{active}">
          <div class="unit">计量单位：{{info.materialUnit}}</div>
          <div class="weight">包重：{{info.bagWeight}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-select-item {
  width: 100%;
  height: 132px;
  border-bottom: 1px solid  #f5dce0;
  box-sizing: border-box;
  padding: 12px 26px 9px 24px;
  background: white;
  font-size: 14px;
  text-align: left;
  &.active {
    background: #CD001F;
  }
  .number-container {
    width: 100%;
    color: #91939A;
    &.active {
      color: white;
    }
  }
  .detail-info-container {
    width: 100%;
    height: 91px;
    box-sizing: border-box;
    padding-top: 11px;
    display: flex;
    justify-content: space-between;
    .image-container {
      width: 80px;
      height: 80px;
      background: #FFFFFF;
      border: 1px solid #E4E4E4;
      opacity: 1;
      border-radius: 5px;
      .image {
        width: 100%;
        height: 100%;
      }
    }
    .detail-info-wrap {
      width: 233px;
      box-sizing: border-box;
      padding-top: 2px;
      .name {
        line-height: 20px;
        height: 40px;
        font-weight: bold;
      }
      &.active {
        color: white;
      }
      .unit-weight-container {
        width: 100%;
        color: #91939A;
        display: flex;
        margin-top: 13px;
        &.active {
          color: white;
        }
        .unit, .weight {
          display: flex;
          flex: 1;
        }
      }
    }
  }
}
</style>