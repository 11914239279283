<template>
  <div class="detail-select-list">
    <BlockImageSelectItem 
      v-for="(item, index) in list" 
      :key="index" 
      :active="active[list[index][activeField]]" 
      :info="item" 
      @click.native="handleClick(index)"
    >
    </BlockImageSelectItem>  
  </div>
</template>

<script>
import BlockImageSelectItem from './blockImageSelectItem.vue'
import { fetchMaterialPrice } from '@/api/select'

export default {
  components: {
    BlockImageSelectItem
  },
  props: {
    value: {},
    list: {
      type: Array,
      default:()=> {
        return []
      }
    },
    activeField: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    single: {
      type: Boolean,
      default: false
    },
    checkPrice: {
      type: Boolean,
      default: true
    },
    chooseList: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      active: {},
      price: {}
    }
  },
  watch: {
    value() {
    }
  },
  beforeMount() {
    this.chooseList.map(item=>{
      this.active[item[this.activeField]] = true
    })
  },
  methods: {
    getAllActive() {
      let ret = []
      this.list.map(item=>{
        if (item[this.activeField] in this.active) {
          ret.push({...item, ...this.price[item[this.activeField]]})
        }
      })
      return ret
    },
    async handleClick(index) {
      let clickItem = this.list[index][this.activeField]
      if (clickItem in this.active) {
        this.$delete(this.price, clickItem)
        this.$delete(this.active, clickItem)
      }
      else {
        if (this.checkPrice) {
          this.$emit('loading', true)
          let response = await fetchMaterialPrice({
            ...this.params,
            materialCode: this.list[index].materialCode
          })
          this.$emit('loading', false)
          this.$set(this.price, clickItem, response.data)
        }
        if (this.single) {
          this.active = {}          
        }
        this.$set(this.active, clickItem, true)
      }
      this.$emit('input', this.getAllActive())
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-select-list {
  width: 100%;
}
</style>