import { render, staticRenderFns } from "./productDetailSelect.vue?vue&type=template&id=522a78b1&scoped=true&"
import script from "./productDetailSelect.vue?vue&type=script&lang=js&"
export * from "./productDetailSelect.vue?vue&type=script&lang=js&"
import style0 from "./productDetailSelect.vue?vue&type=style&index=0&id=522a78b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522a78b1",
  null
  
)

export default component.exports